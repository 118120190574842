// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfahrt-js": () => import("./../../../src/pages/anfahrt.js" /* webpackChunkName: "component---src-pages-anfahrt-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index_2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */)
}

